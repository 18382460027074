import React, { useState, useEffect } from 'react';
import { Page, Toast, Frame } from '@shopify/polaris';
import { withFirebase } from '../../../../providers/firebase'
import { navigate } from "gatsby"
import equal from "deep-equal"
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import getSymbolFromCurrency from 'currency-symbol-map';
import HideDeliveryMethodsForm from './hideDeliveryMethodsForm';
import DocStatus from '../../../../components/docStatus';
import { createDeliveryCustomization, updateDeliveryCustomization, deleteDeliveryCustomization} from '../../../../helpers/deliveryCustomization';
import styled from 'styled-components';
const FrameWrapper = styled.div`
.Polaris-Frame {
  max-height: 0;
  min-height: 0;
}
`
function CreateHideDeliveryMethods(props) {
    const { token, shop, location, firebase, host } = props
    const existingCustomization = (location && location.state && location.state.customization) || null
    const from = location && location.state && location.state.from
    const isEditing = !!existingCustomization
    const isCreating = !isEditing
  
    const initialEditingState = {
      id: existingCustomization && existingCustomization.id || null,
      deliveryMethodName: existingCustomization && existingCustomization.deliveryMethodName || '',
      deliveryMethodNameOption: existingCustomization && existingCustomization.deliveryMethodNameOption || 'nameContains',
      enabled: existingCustomization && existingCustomization.enabled || false,
      triggers: existingCustomization && existingCustomization.triggers || {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
            }
        }
      },
      type: "custom field",
    }
    const [state, setState] = useState(initialEditingState)
    const [productPickerShow, setProductPickerShow] = useState('product')
    const [error, setError] = useState(null)
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
      shopDoc
      );
      const [isSubmitting, setIsSubmitting] = useState(false)
      const currency = (shopData && shopData.shopData && shopData.shopData.currency) ? shopData.shopData.currency : 'USD';
      const locale = shopData && shopData.shopData && shopData.shopData.primary_locale || "en-US";
      const domain = shopData && shopData.shopData && shopData.shopData.domain
      const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : '$';
      let currentState = Object.assign({},state);
      let initialStateForCheck = Object.assign({},initialEditingState);
      delete currentState.enabled;
      delete initialStateForCheck.enabled;
      delete currentState.updatedAt;
      delete initialStateForCheck.updatedAt;
      const hasUnsavedChanges = (isCreating || isEditing) && !equal(currentState, initialStateForCheck)
      const handleFormSubmit = async (type) => {
        console.log("type", type)
        if (isSubmitting) {
          return
        }
        setIsSubmitting(true)
        if (isEditing) {
          await updateDeliveryCustomization(state, token, shop, host)
          navigate(
            `/app/customizations/hidedeliverymethods/createHideDeliveryMethods`,
            {
              state: {customization: {...state, updatedAt: new Date().toISOString()}, from: "Changes saved",},
              replace: true,
            }
          )
        }else{
          console.log("saving")
          const result = await createDeliveryCustomization(state, token, shop, host)
          if(result?.data?.customizationId){
            navigate(
              `/app/customizations/hidedeliverymethods/hideDeliveryMethodsItem`,
              {
                state: {customization: {...state, id: result.data.customizationId.replace("gid://shopify/DeliveryCustomization/", ""), updatedAt: new Date().toISOString()}, from: "Customization saved",},
                replace: true,
              }
            )
          }else{
            if(result?.data?.error){
              setError(result.data.error)
              setIsSubmitting(false)
              return "error"
            }
          }
        }
        setIsSubmitting(false)
      }
      const handleCustomContentDelete = async () => {
        const result = await deleteDeliveryCustomization(state.id, shop, token);
        console.log("result", result)
        navigate('/app/customizations', {
          state: location.state,
          replace: true,
        })
      }
      
      
    return (
          <DocStatus isEditing={isEditing} location={location} name="Hide delivery method" updatedAt={existingCustomization?.updatedAt} handleFormSubmit={handleFormSubmit} isSubmitting={isSubmitting} hasUnsavedChanges={hasUnsavedChanges} token={token} shop={shop} firebase={firebase} state={state} setState={setState} from={from} domain={domain} fullWidth title="Hide delivery method" initialEditingState={initialEditingState}>
            <HideDeliveryMethodsForm token={token} shop={shop} location={location} firebase={firebase} host={host} error={error} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} isCreating={isCreating} isEditing={isEditing} isSubmitting={isSubmitting} handleFormSubmit={handleFormSubmit} hasUnsavedChanges={hasUnsavedChanges} handleCustomContentDelete={handleCustomContentDelete}/>
           
          </DocStatus>
    );
}

export default withFirebase(CreateHideDeliveryMethods);
